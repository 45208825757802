@import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
@import "../node_modules/alertifyjs/build/css/alertify.css";
@import '../node_modules/alertifyjs/build/css/themes/bootstrap.min.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}

button {
    white-space: pre-wrap;
}

.divider {
    width: 100%;
    border: 1px solid black;
}

.dotted-divider {
    width: 100%;
    border: 1px dotted black;
}

.ml-1 {
    margin-left: 10px
}

.ml-2 {
    margin-left: 20px
}

.ml-3 {
    margin-left: 30px
}

.ml-4 {
    margin-left: 40px
}

.ml-5 {
    margin-left: 50px
}

.mr-1 {
    margin-right: 10px
}

.mr-2 {
    margin-right: 20px
}

.mr-3 {
    margin-right: 30px
}

.mr-4 {
    margin-right: 40px
}

.mr-5 {
    margin-right: 50px
}

.mt-1 {
    margin-top: 10px
}

.mt-2 {
    margin-top: 20px
}

.mt-3 {
    margin-top: 30px
}

.mt-4 {
    margin-top: 40px
}

.mt-5 {
    margin-top: 50px
}

.mb-1 {
    margin-bottom: 10px
}

.mb-2 {
    margin-bottom: 20px
}

.mb-3 {
    margin-bottom: 30px
}

.mb-4 {
    margin-bottom: 40px
}

.mb-5 {
    margin-bottom: 50px
}

.m-1 {
    margin: 10px;
}

.m-2 {
    margin: 20px;
}

.m-3 {
    margin: 30px;
}

.m-4 {
    margin: 40px;
}

.m-5 {
    margin: 50px;
}

.container-fluid {
    height: 100%;
    width: 100%;
}

.red {
    background: red;
}

.blue {
    background: blue
}

.yellow {
    background: yellow
}

.green {
    background: green;
}

.overflow-auto {
    overflow: auto;
}

hr {
    width: 100%;
}

.text-muted {
    color: lightslategray;
}

.text-capitalize {
    text-transform: uppercase;
}

.text-smaller {
    font-size: small;
}

.text-wrap {
    word-wrap: break-word;
}

.mdc-notched-outline__notch
{
    border-right: none;
}
